<template>
    <v-card v-ripple :class="cssClass" @click="$emit('click')">
        <div class="thumbnail-card__preview-container">
            <slot></slot>
        </div>

        <div class="thumbnail-card__body">
            <v-icon v-if="icon" class="thumbnail-card__icon" size="20">{{ icon }}</v-icon>

            <div class="thumbnail-card__copy">
                <div class="thumbnail-card__title">{{ title }}</div>
                <div class="thumbnail-card__subtitle" v-if="subtitle">{{ subtitle }}</div>
            </div>

            <div v-if="$slots.actions" class="thumbnail-card__actions">
                <slot name="actions"></slot>
            </div>
        </div>

        <div v-if="$slots.footer" class="thumbnail-card__footer">
            <slot name="footer"></slot>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        modifier: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
    },
    computed: {
        cssClass() {
            return {
                'thumbnail-card': true,
                [`thumbnail-card--${this.modifier}`]: !!this.modifier,
                'thumbnail-card--disabled': this.disabled,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.thumbnail-card {
    $self: &;

    background-color: white;
    border: 1px solid #eaecf0;
    border-radius: var(--pages-border-radius);
    box-shadow: $elevation2;
    cursor: pointer;
    overflow: hidden;

    &__preview-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
        background-color: #f2f4f7;
        overflow: hidden;
    }

    &__body {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 16px 24px;
        min-height: 68px;
    }

    &__icon {
        color: inherit;
    }

    &__copy {
        overflow: hidden;
        width: 100%;
    }

    &__title {
        line-height: 24px;
        color: #101828;
        font-size: 16px;
        font-weight: 600;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__subtitle {
        color: #667085;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__actions {
        margin-right: -8px;
        user-select: none;
    }

    &__footer {
        padding: 16px 24px;
        border-top: 1px solid #eaecf0;
    }

    &:hover:not(&--disabled) {
        box-shadow: $elevation4;
    }

    &--widget-preview {
        &#{ $self }--disabled {
            opacity: 0.75;
            cursor: not-allowed;
        }
    }

    &--addwidget {
        #{ $self }__preview-container {
            height: 200px;
        }
    }

    &--whatsapp {
        #{ $self }__preview-container {
            height: 200px;
        }
    }
}
</style>
