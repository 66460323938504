<template>
    <div class="empty-state-art fadeIn" style="position: relative; overflow: hidden">
        <div class="background" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0">
            <slot name="background">
                <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); gap: 20px">
                    <div
                        v-for="n in 30"
                        :key="n"
                        style="border-radius: 6px; overflow: hidden; border: 1px solid #f2f4f7"
                    >
                        <div
                            style="
                                background-color: #f2f4f7;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                gap: 14px;
                                padding: 40px;
                                height: 250px;
                                overflow: hidden;
                            "
                        >
                            <div
                                style="width: 100%; background-color: white; border-radius: 12px; margin-bottom: -60px"
                            ></div>
                            <div
                                class="gradient-card"
                                style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
                            ></div>
                        </div>

                        <div
                            style="
                                background-color: white;
                                display: flex;
                                gap: 20px;
                                justify-content: space-between;
                                padding: 20px;
                            "
                        >
                            <div style="display: flex; align-items: center; gap: 20px; width: 100%">
                                <div
                                    style="
                                        border-radius: 999px;
                                        height: 40px;
                                        aspect-ratio: 1/1;
                                        background-color: #f2f4f7;
                                    "
                                ></div>
                                <div
                                    style="height: 16px; width: 70%; border-radius: 999px; background-color: #f2f4f7"
                                ></div>
                            </div>

                            <v-icon color="#E9ECF0">mdi-dots-vertical</v-icon>
                        </div>
                    </div>
                </div>
            </slot>
        </div>

        <div class="fade" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"></div>

        <div
            style="
                position: relative;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
            "
            :style="{ padding: `${this.paddingHeight} 20px` }"
        >
            <EmptyStateCard
                class="dialog fadeIn"
                style="
                    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                    margin-top: 0;
                "
                v-bind="{
                    ...emptyPropsDefault,
                    ...emptyProps,
                }"
            >
                <slot></slot>

                <template #buttons>
                    <slot name="buttons" />
                </template>
            </EmptyStateCard>
        </div>
    </div>
</template>

<script>
import EmptyStateCard from '@/components/EmptyStateCard.vue';

export default {
    components: {
        EmptyStateCard,
    },
    props: {
        // same props object as the EmptyStateCard
        emptyProps: {
            type: Object,
        },
        paddingHeight: {
            type: String,
            default: '200px',
        },
    },
    data() {
        return {
            emptyPropsDefault: {
                backgroundColor: 'white',
                border: true,
                maxWidth: '445px',
                titleBalancedWrap: true,
            },
        };
    },
};
</script>

<style scoped lang="scss">
.empty-state-art {
}

.dialog {
    animation-delay: 0.05s;
}

.fade {
    background: linear-gradient(180deg, transparent 50%, rgba(#f9fafb, 1) 100%);
}

.gradient-card {
    background: linear-gradient(180deg, transparent 50%, rgba(#f2f4f7, 1) 100%);
}
</style>
